import { FRESHCHAT_HOST, getFreshchatWidgetByCityProductAndLanguage, config } from '@util/config'
import { useCallback, useMemo } from 'react'
import { useCityCode } from './useCityCode'
import { useRouter } from 'next/router'
import { useGlobalState } from '@contexts/global'

export const useFreshChat = () => {
    const { locale } = useRouter()
    const { city } = useCityCode()

    const { bookingState } = useGlobalState()

    const widgetGuid = useMemo(
        () => getFreshchatWidgetByCityProductAndLanguage(city, bookingState.productType, locale || 'en'),
        [bookingState.productType, city, locale],
    )

    const freshChatInitObject = useMemo(
        () => ({
            token: config.FCWIDGET_TOKEN,
            ...(widgetGuid ? { widgetUuid: widgetGuid } : {}),
            ...(locale ? { locale: locale === 'default' ? 'en' : locale.slice(0, 2) } : {}),
            host: FRESHCHAT_HOST,
            tags: ['dailyrental'],
            open: false,
            config: {
                headerProperty: { direction: 'ltr' },
            },
        }),
        [locale, widgetGuid],
    )

    const setProperties = useCallback((value: string) => {
        if (typeof window !== 'undefined' && window.fcWidget) {
            window.fcWidget.on('widget:loaded', () => {
                window.fcWidget.user.setProperties({
                    isCareem: value,
                })
                return
            })

            if (window?.fcWidget?.isInitialized()) {
                window.fcWidget.user.setProperties({
                    isCareem: value,
                })
                return
            }
        }
    }, [])

    const initFreshChat = useCallback(() => {
        if (typeof window !== 'undefined' && window.fcWidget) {
            window.fcWidget.init(freshChatInitObject)

            window.fcWidget.open()
        }
    }, [freshChatInitObject])

    const openFreshChat = useCallback(() => {
        if (!window?.fcWidget?.isInitialized()) initFreshChat()
        if (!window.fcWidget.isLoaded()) return
        window.fcWidget.open()
    }, [initFreshChat])

    const hideFreshChat = useCallback(() => {
        if (typeof window !== 'undefined' && window.fcWidget) {
            window.fcWidget.hide()
        }
    }, [])

    const showFreshChat = useCallback(() => {
        if (typeof window !== 'undefined' && window.fcWidget) {
            window.fcWidget.show()
        }
    }, [])

    return { openFreshChat, freshChatInitObject, setProperties, hideFreshChat, showFreshChat }
}
